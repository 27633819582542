import React from "react";

import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
import LogoRedbull from "../images/logos/brand/grey-hubspot.svg";
import LogoHubspot from "../images/logos/brand/grey-red-bull.svg";
import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";

const TrustedCompaniesBlock = () => (
  <div className="container">
    <div className="row text-center mb-3">
      <div className="col">
        <small className="">Trusted by companies like</small>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <ul className="Companieslogo d-flex align-items-center justify-content-center list-unstyled">
          <li>
            <LogoSamsung />
          </li>
          <li>
            <LogoTmobile />
          </li>
          <li>
            <LogoRedbull />
          </li>
          <li>
            <LogoHubspot />
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default TrustedCompaniesBlock;

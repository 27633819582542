import React from "react";
import {graphql, Link} from "gatsby";

import Layout from "../components/layout";
import TrustedCompaniesBlock from "../components/TustedCompaniesBlock";

import useResizeScreen from "../utils/resize";

// import imageFirstSection from "../images/photos/fintech-design-ux.png";
// import imageSecondSection from "../images/photos/design-fintech-app.jpg";
// import imageFinanceBoring from "../images/photos/payment_solution_platform_design.png";
// import imageFinanceBoringMobile from "../images/photos/payment_solution_platform_design-mobile.jpg";
// import imageDontBeLeftBehind from "../images/photos/Payment_Platform_B2B.jpg";
// import imageDontBeLeftBehindMobile from "../images/photos/Payment_Platform_B2B_mobile.jpg";
// import imageUxChalenges from "../images/photos/ux-challenges-finance-app.jpg";
// import imageBadUx from "../images/photos/financial-app-design.jpg";

import IconCheck from "../images/icons/interface/check.svg";
import Divider1 from "../images/dividers/divider-1.svg";
import DecorationS5Blob from "../images/decorations/landing-pages/lp-1-s-5-blob.svg";
// import IconList from "../images/icons/theme/code/warning-1-circle.svg";
import IconHome from "../images/icons/theme/home/building.svg";
import IconChartLine from "../images/icons/theme/shopping/chart-line-1.svg";
import IconIphonePayment from "../images/icons/theme/devices/iphone-x-payment.svg";
import IconBitcoin from "../images/icons/theme/shopping/bitcoin.svg";
import IconArrowLong from "../images/icons/interface/arrow-long.svg";
import IconBird1 from "../images/icons/interface/bird-1.svg";
import IconBird2 from "../images/icons/interface/bird-2.svg";
import IconBird3 from "../images/icons/interface/bird-3.svg";

import IconHeart from "../images/icons/theme/general/heart.svg";
import IconTailored from "../images/icons/theme/tools/compass.svg";
import IconSun from "../images/icons/theme/weather/sun.svg";
import {GatsbyImage} from "gatsby-plugin-image";
import {FintechJsonLd} from "../components/jsonld/FintechJsonLd2";

const FintechDesignPage = ({location: {pathname}, data}) => {
    const {isMobile, isTablet} = useResizeScreen();

    return (
        <Layout
            meta={{
                seo: data.datoCmsPage.seo,
                path: pathname
            }}
            footerBgClass="bg-primary-3"
        >
            <FintechJsonLd/>
            <div className="landing-page-1">
                <SectionFirstScreen
                    isMobile={isMobile}
                    isTablet={isTablet}
                    data={data}
                />
                <SectionSecondScreen data={data}/>
                <SectionTrustedCompanies/>
                <SectionFourthScreen
                    isMobile={isMobile}
                    isTablet={isTablet}
                    data={data}
                />
                <SectionFifthScreen isMobile={isMobile}/>
                <SectionSixthScreen isMobile={isMobile} isTablet={isTablet}/>
                <SectionSeventhScreen
                    isMobile={isMobile}
                    isTablet={isTablet}
                    data={data}
                />
                <SectionEighthScreen isMobile={isMobile}/>
                <SectionNinthScreen isMobile={isMobile}/>
            </div>
        </Layout>
    );
};

const SectionFirstScreen = ({isTablet, data}) => {
    const imageFirstSection = data.imageFirstSection.childImageSharp.gatsbyImageData;

    return (
        <section className="section-first o-hidden py-5">
            <div className="container">
                <div className="row align-items-center min-vh-60">
                    <div className="col-lg-7 text-center text-lg-left mb-4 mb-lg-0">
                        {!isTablet && (
                            <span className="alert alert-primary">
                We Only Take Limited Number of Clients at a Time
              </span>
                        )}
                        <h1 className="display-4">
                            We're a Fintech Design Agency:
                            {/* <br/> We make financial products crystal clear */}
                        </h1>
                        <div className="my-5">
                            {/* <p className="h4 mb-4">
                            Here’s how we turn UX into your competitive advantage 
                            </p> */}
                            <ul className="list text-left">
                                <li className="list-item list-item-primary">
                                Make your Fintech product crystal-clear
                                </li>
                                <li className="list-item list-item-primary">
                                Let us skyrocket conversions, retention and engagement
                                </li>
                                <li className="list-item list-item-primary">
                                Emerge victorious out of the upcoming recession
                                </li>
                            </ul>
                        </div>
                        {/* <div className="my-5">
                            <p className="lead">
                                Let us <span>skyrocket</span> your conversions, retention, and
                                engagement.
                            </p>
                        </div> */}
                        <Link
                            to="/contact-us"
                            id="freeconsultation"
                            className="btn btn-lg btn-primary"
                        >
                            {/* {!isMobile ? `Schedule Free Strategy Session` : `Free Strategy Session`} */}
                            Contact Us
                        </Link>
                    </div>
                    <div className="col-lg-5 text-center ">
                        <div className="min-vw-50">
                            <GatsbyImage objectFit="contain"
                                         alt={"Image"}
                                         style={{width: 800}}
                                         image={imageFirstSection}
                                         className=" " loading="eager"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const SectionSecondScreen = ({data}) => {
    const imageSecondSection = data.imageSecondSection.childImageSharp.gatsbyImageData;

    return (
        <section className="second-section">
            <div className="container">
                <h3 className="h2">Make a financial tool usable</h3>
                <p className="mb-4 lead">
                    We pride ourselves in the dedication that we give to each project —
                    this is the reason our Fintech designs not only stand out but also get
                    raving feedback from users, as well as clients.
                </p>
                <div className="decoration">
                    <GatsbyImage objectFit="contain" loading={"lazy"} image={imageSecondSection} alt="Image"
                                 className="w-100"/>
                </div>
            </div>
        </section>
    );
};

const SectionTrustedCompanies = () => (
    <section className="py-0">
        <TrustedCompaniesBlock/>
    </section>
);

const SectionFourthScreen = ({isMobile, isTablet, data}) => {
    const imageFinanceBoring = data.imageFinanceBoring.childImageSharp.gatsbyImageData;
    const imageFinanceBoringMobile =
        data.imageFinanceBoringMobile.childImageSharp.gatsbyImageData;
    const imageDontBeLeftBehind =
        data.imageDontBeLeftBehind.childImageSharp.gatsbyImageData;
    const imageDontBeLeftBehindMobile =
        data.imageDontBeLeftBehindMobile.childImageSharp.gatsbyImageData;

    const imageSourceFinanceBoring = isMobile
        ? imageFinanceBoringMobile
        : imageFinanceBoring;
    const imageSourceStayAhead = isMobile
        ? imageDontBeLeftBehindMobile
        : imageDontBeLeftBehind;

    return (
        <section className="o-hidden fourth-section">
            <div className="top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 content lead">
                            <h3 className="h2">Make finance exciting</h3>
                            <p>
                                Did you know that customer adoption for Fintech products stands
                                at just <span>33%?</span>
                            </p>
                            <p>
                                Our fintech design agency helps design products that's easy-to-use regardless
                                of the complexity of it's functionality, while adhering to the
                                plethora of regulations that govern FinTech.
                            </p>
                            <Link
                                to="/contact-us"
                                className={`btn btn-lg mt-3 ${
                                    !isMobile ? "btn-primary" : "btn-primary"
                                }`}
                            >
                                {/* {!isTablet ? `Schedule Free Strategy Session` : `Free Strategy Session`} */}
                                Contact Us
                            </Link>
                        </div>
                        <div className="col-md-6">
                            <GatsbyImage objectFit="contain" alt={"Make finance exciting"}
                                         style={{display: "inline-block"}}
                                         image={imageSourceFinanceBoring} loading={"lazy"}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bottom">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-6 order-1 order-md-0 image">
                            <GatsbyImage alt={"Always stay ahead"}
                                         style={{display: "inline-block"}}
                                         image={imageSourceStayAhead} loading={"lazy"}/>
                        </div>
                        <div className="col-md-6 content lead">
                            <h3 className="h2">Always stay ahead</h3>
                            <p>
                                Bad UX is the real reason that people have been slow in adopting
                                Fintech.
                            </p>
                            <p>
                                If you are serious about winning them over, your platforms must
                                have a user interface that strikes the balance between
                                simplicity and usefulness.  
                            </p>

                            {/* We know what your customers and clients want: */}

                            {/* <div className="list">
                  <div>
                    <div>
                      <IconList />
                    </div>
                    <span>access to their money immediately</span>
                  </div>
                  <div>
                    <div>
                      <IconList />
                    </div>
                    <span>start trading without delay</span>
                  </div>
                  <div>
                    <div>
                      <IconList />
                    </div>
                    <span>their loans pronto</span>
                  </div>
                </div> */}

                            <p>
                                Act now, and we will help you ensure that your financial tools
                                are user-friendly.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const SectionFifthScreen = ({}) => (
    <section className="fifth-section bg-primary-alt has-divider">
        <div className="divider">
            <Divider1/>
        </div>

        <div className="decoration-wrapper">
            <div className="decoration">
                <DecorationS5Blob/>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-md-10 mx-auto">
                    <div className="content lead">
                        <h3 className="h2">Be ahead of the curve</h3>
                        <p>
                            Get an edge in an increasingly competitive Fintech landscape through
                            design. Impeccable UX leads to:
                        </p>
                    </div>

                    <div className="container pt-3 pb-3">
                        <div className="row lead">
                            <div className="col-md-6 d-flex mb-4">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary">
                                    <IconCheck className="injected-svg icon bg-white"/>
                                </div>
                                <div className="ml-2">
                                    <p>Dominate your niche and stay ahead of competitors </p>
                                </div>
                            </div>

                            <div className="col-md-6 d-flex mb-4">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary">
                                    <IconCheck className="injected-svg icon bg-white"/>
                                </div>
                                <div className="ml-2">
                                    <p>Attract investor funding</p>
                                </div>
                            </div>

                            <div className="col-md-6 d-flex mb-4">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary">
                                    <IconCheck className="injected-svg icon bg-white"/>
                                </div>
                                <div className="ml-2">
                                    <p>Minimize the number of bad reviews and ratings</p>
                                </div>
                            </div>

                            <div className="col-md-6 d-flex mb-4">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary">
                                    <IconCheck className="injected-svg icon bg-white"/>
                                </div>
                                <div className="ml-2">
                                    <p>Watch your conversions grow and focus on optimization</p>
                                </div>
                            </div>

                            <div className="col-md-6 d-flex mb-4">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary">
                                    <IconCheck className="injected-svg icon bg-white"/>
                                </div>
                                <div className="ml-2">
                                    <p>Skyrocket customer engagement and your product's usage</p>
                                </div>
                            </div>

                            <div className="col-md-6 d-flex">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary">
                                    <IconCheck className="injected-svg icon bg-white"/>
                                </div>
                                <div className="ml-2">
                                    <p>Gain customers' trust right off the bat</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="text-center lead">
            <p><strong>All this because you ignored an issue as simple as bad UX.</strong></p>
          </div> */}
                </div>
            </div>
        </div>
    </section>
);

const SectionSixthScreen = ({isMobile, isTablet}) => (
    <section className="sixth-section bg-primary-3 py-4 py-lg-7">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 text-light caption">
                    <div>
                        <h3 className="h2 pb-3 pb-lg-7">
                            Benefits that you will enjoy while working with us:
                        </h3>

                        {!isTablet && (
                            <Link
                                to="/contact-us"
                                id="freeconsultation"
                                className="btn btn-lg btn-primary"
                            >
                                {/* {!isMobile ? `Schedule Free Strategy Session` : `Free Strategy Session`} */}
                                Contact Us
                            </Link>
                        )}
                    </div>
                </div>
                <div className="col-lg-6 features">
                    <div>
                        <div className="card card-body">
                            <div>
                                <IconHeart className="injected-svg icon icon-lg bg-primary"/>
                            </div>
                            <p className="lead">
                                We guarantee to create a product fit for your audience based on
                                their needs.
                            </p>
                        </div>
                        <div className="card card-body">
                            <div>
                                <IconTailored className="injected-svg icon icon-lg bg-primary"/>
                            </div>
                            <p className="lead">
                                We create a roadmap tailored to your needs, expectations &
                                budget.
                            </p>
                        </div>
                        <div className="card card-body">
                            <div>
                                <IconSun className="injected-svg icon icon-lg bg-primary"/>
                            </div>
                            <p className="lead">
                                {!isMobile
                                    ? `We bring everything to the table, offering insights on research, design, developers' handoffs. `
                                    : ` We offer insights on research, design, devs' handoffs.`}
                            </p>
                        </div>
                    </div>

                    {isTablet && (
                        <div className="mt-4 text-center">
                            <Link to="/estimate-project" className="btn btn-lg btn-yellow">
                                {/* {!isMobile ? `Schedule Free Strategy Session` : `Free Strategy Session`} */}
                                Get a Free Estimate
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </section>
);

const SectionSeventhScreen = ({isMobile, isTablet, data}) => {
    const imageBadUx = data.imageBadUx.childImageSharp.gatsbyImageData;
    const imageUxChalenges = data.imageUxChalenges.childImageSharp.gatsbyImageData;

    return (
        <section className="seventh-section">
            <div className="container">
                <div className="content">
                    <h3 className="h2">
                        {!isMobile
                            ? `Fintech is the future. (And) we understand `
                            : `Fintech is the future.`}
                        <span>why:</span>
                    </h3>
                </div>

                <div className="row first-row lead">
                    {!isTablet && (
                        <div className="col-lg-6">
                            <GatsbyImage loading={"lazy"} objectFit="contain" image={imageBadUx} alt="" className=""
                                         fadeIn={false}/>
                        </div>
                    )}
                    <div className="col-lg-6 blocks">
                        <div className="block">
                            <div className="top">
                                <IconBird1/>
                                <p className="lead">
                                    <strong>Economic Growth</strong>
                                </p>
                            </div>
                            <p>
                                With digital payments, anyone can sell, buy, or carry out
                                transactions from anywhere on the globe.
                            </p>
                        </div>
                        <div className="block block--2">
                            <div className="top">
                                <IconBird2/>
                                <p className="lead">
                                    <strong>Better Security</strong>
                                </p>
                            </div>
                            <p>
                                Biometric data and encryption let people do business and carry
                                out financial transactions in peace.
                            </p>
                        </div>
                        <div className="block block--3">
                            <div className="top">
                                <IconBird3/>
                                <p className="lead">
                                    <strong>Easy Payments</strong>
                                </p>
                            </div>
                            <p>
                                With digital payments, it's no longer necessary to carry cash or
                                write checks.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row second-row lead">
                    <div className="col-lg-6 order-1 order-lg-0">
                        <div className="block block--1">
                            <div className="top">
                                <IconBird1/>
                                <p className="lead">
                                    <strong>Better Banking Experience</strong>
                                </p>
                            </div>
                            <p>
                                Customers get a customized banking experience and faster
                                transactions. Banks have cut operating costs, increasing profits
                                in the process.
                            </p>
                        </div>
                        <div className="block block--2">
                            <div className="top">
                                <IconBird2/>
                                <p className="lead">
                                    <strong>Accessible Finance</strong>
                                </p>
                            </div>
                            <p>
                                Open a bank account in just minutes. Investors have access to
                                more markets. Anyone can apply for a loan or mortgage and have
                                it in days.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <GatsbyImage
                            loading={"lazy"}
                            objectFit="contain"
                            image={imageUxChalenges}
                            alt=""
                            className="d-block mx-auto my-3 my-lg-auto mr-lg-0"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

const SectionEighthScreen = ({isMobile}) => {
    const settings = {
        autoplay: false,
        arrows: false,
        dots: false,
        infinite: true,
        speed: 1750,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        variableWidth: true
    };

    return (
        <section className="eight-section bg-primary-alt has-divider">
            <div className="divider">
                <Divider1/>
            </div>

            <div className="container">
                <div className="content">
                    <h3 className="h2">Your Trusted Fintech Partner</h3>
                    <p className="lead">
                        {!isMobile
                            ? `Regardless of what kind of FinTech product you work on, we've got you covered:`
                            : "We can help with the following:"}
                    </p>
                </div>
                <div className="row cards">
                    <div className="col-md-6 col-lg-3">
                        <div className="card card--common card-body">
                            <div className="card--front">
                                <div className="icon-round bg-primary mb-4">
                                    <IconIphonePayment className="icon icon-lg align-items-left "/>
                                </div>
                                <h4>Payment & Banking</h4>
                                <IconArrowLong className="arrow"/>
                            </div>
                            <div className="card--back">
                                <h4>Payment & Banking</h4>
                                <p>
                                    Whether you want to digitize your activities or any programs,
                                    we can help with that. We are experts at designing payment
                                    platforms that not only get work done but are also a delight
                                    to use.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="card card--common card-body">
                            <div className="card--front">
                                <div className="icon-round bg-primary mb-4">
                                    <IconHome className="icon icon-lg align-items-left "/>
                                </div>
                                <h4>Mortgage & Insurance</h4>
                                <IconArrowLong className="arrow"/>
                            </div>
                            <div className="card--back">
                                <h4>Mortgage & Insurance</h4>
                                <p>
                                    We can help you design a system that's simpler and faster and
                                    more desirable, memorable, accessible, and credible. We can
                                    help you give loans and insurance to your customers without
                                    any hassle.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="card card--common card-body">
                            <div className="card--front">
                                <div className="icon-round bg-primary mb-4">
                                    <IconChartLine className="icon icon-lg align-items-left "/>
                                </div>
                                <h4>Strategic & Analytical Tools</h4>
                                <IconArrowLong className="arrow"/>
                            </div>
                            <div className="card--back">
                                <h4>Strategic & Analytical Tools</h4>
                                <p>
                                    We can help you design a system that's simpler and faster and
                                    more desirable, memorable, accessible, and credible. We can
                                    help you give loans and insurance to your customers without
                                    any hassle.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="card card--common card-body">
                            <div className="card--front">
                                <div className="icon-round bg-primary mb-4">
                                    <IconBitcoin className="icon icon-lg align-items-left "/>
                                </div>
                                <h4>Blockchain & Cryptocurrency</h4>
                                <IconArrowLong className="arrow"/>
                            </div>
                            <div className="card--back">
                                <h4>Blockchain & Cryptocurrency</h4>
                                <p>
                                    With nearly 3,000 cryptocurrencies out there, you will need a
                                    design that instantly hooks customers. We have a track record
                                    of helping companies in this niche instantly win customer
                                    trust.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const SectionNinthScreen = ({isMobile}) => (
    <section className="ninth-section">
        <div className="container">
            <div className="content">
                <h4 className="display-4">
                    We work with a limited number of clients at a time
                </h4>
                <p className="mb-5 lead">
                    {!isMobile
                        ? `Start building the perfect UX for your Fintech apps, or any other tools.`
                        : `Start building the perfect UX for your Fintech platform.`}
                </p>
                <Link to="/contact-us" className="btn btn-lg btn-primary">
                    {/* {!isMobile ? `Schedule Free Strategy Session` : `Free Strategy Session`} */}
                    Contact Us
                </Link>
            </div>
        </div>
    </section>
);

export default FintechDesignPage;

export const query = graphql`query FintechDesignPage {
    imageFirstSection: file(relativePath: {eq: "photos/fintech-design-ux.png"}) {
        childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1200, placeholder: BLURRED)
        }
    }
    imageSecondSection: file(relativePath: {eq: "photos/design-fintech-app.jpg"}) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    imageFinanceBoring: file(
        relativePath: {eq: "photos/payment_solution_platform_design.png"}
    ) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    imageFinanceBoringMobile: file(
        relativePath: {eq: "photos/payment_solution_platform_design-mobile.jpg"}
    ) {
        childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 800)
        }
    }
    imageDontBeLeftBehind: file(
        relativePath: {eq: "photos/Payment_Platform_B2B.jpg"}
    ) {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    imageDontBeLeftBehindMobile: file(
        relativePath: {eq: "photos/Payment_Platform_B2B_mobile.jpg"}
    ) {
        childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 800)
        }
    }
    imageUxChalenges: file(
        relativePath: {eq: "photos/ux-challenges-finance-app.jpg"}
    ) {
        childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1200)
        }
    }
    imageBadUx: file(relativePath: {eq: "photos/financial-app-design.jpg"}) {
        childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1200)
        }
    }
    datoCmsPage(slug: {eq: "fintech-design"}) {
        seo {
            title
            description
            twitterCard
            image {
                url
            }
        }
    }
}
`;
